<!--商户订单提现-->
<template>
  <div id="merchantOrders">
    <div class="table-search">
      <div style="display: flex">
        <div class="search-L">
          <el-select
            v-model="tableParams.merchantId"
            placeholder="请选择商户"
            clearable
            @change="reload"
            class="mr15"
          >
            <el-option
              v-for="item in commercialList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="tableParams.orderType"
            placeholder="请选择订单类型"
            clearable
            @change="reload"
            class="mr15"
          >
            <el-option
              v-for="item in ObjToOpt(orderTypeObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="tableParams.settleType"
            placeholder="请选择结算状态"
            clearable
            @change="reload"
            class="mr15"
          >
            <el-option
              v-for="item in ObjToOpt(settleObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-date-picker
          v-model="dateToDate"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="~"
          start-placeholder="结算开始日期"
          end-placeholder="结算结束日期"
          :picker-options="pickerOptions"
          @change="goSelectDate()"
          clearable
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goClick(1)">提现</el-button>
        <el-button type="primary" @click="goClick(2)">提现记录</el-button>
      </div>
    </div>
    <div class="money">
      <div class="item">
        <div class="t1">待结算金额</div>
        <div class="t2">1000</div>
      </div>
      <div class="item">
        <div class="t1">可提现金额</div>
        <div class="t3">10000</div>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="交易时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单内容" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="实付金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结算状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roomCount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click.stop="goClick(3, scope.row)"
            >分账详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!--提现弹框-->
    <el-dialog
      :visible.sync="withdrawShow"
      title="提现"
      :close-on-click-modal="false"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 可提现金额 -->
        <el-form-item label="可提现金额" label-width="120px"
          >¥100
        </el-form-item>
        <!-- 提现审核中金额 -->
        <el-form-item label="提现审核中金额" label-width="120px">
          ¥10</el-form-item
        >
        <!-- 提现金额 -->
        <el-form-item label="提现金额" label-width="120px" prop="money">
          <el-input
            v-model.trim="params.money"
            autocomplete="off"
            maxlength="16"
            placeholder="请输入提现金额"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button @click="withdrawShow = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--提现记录弹框-->
    <el-dialog
      :visible.sync="withdrawRecordShow"
      title="提现记录"
      :close-on-click-modal="false"
      width="1100px"
      custom-class="add-dialog"
    >
      <div class="totalWithdrawmoney">
        已提现总额：¥{{ totalWithdrawmoney }}
      </div>
      <el-table
        :data="withdrawData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column
          label="审核发起时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.merchantName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="提现发起时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.shopName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现金额" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.serviceName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.roomCount || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="审核拒绝原因"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roomCount || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.roomCount || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="提现失败原因"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roomCount || "0" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="到账银行卡"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.roomCount || "0" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--分账弹框-->
    <el-dialog
      :visible.sync="shareProfitsShow"
      title="分账详情"
      :close-on-click-modal="false"
      width="800px"
      custom-class="add-dialog"
    >
      <el-table
        :data="shareData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column
          label="分账接收方"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.merchantName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分账比例" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.shopName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分账金额" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.serviceName || "-" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { orderTypeObj, settleObj, pickerOptions } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";

export default {
  name: "MerchantOrders",
  data() {
    return {
      rules,
      orderTypeObj,
      pickerOptions,
      settleObj,
      ObjToOpt,
      tableParams: {
        merchantId: "",
        orderType: "",
        settleType: "",
        startTime: "",
        endTime: "",
        page: 1,
        size: 10,
      },
      commercialList: [],
      dateToDate: [],
      tableData: [{ merchantName: 1 }],
      total: 0,
      currentPage: 1,
      withdrawShow: false,
      params: {
        money: "",
      },
      withdrawRecordShow: false,
      totalWithdrawmoney: 0,
      withdrawData: [],
      shareProfitsShow: false,
      shareData: [],
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
  },
  methods: {
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
    // 点击
    goClick(type, row) {
      switch (type) {
        case 1:
        this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
          this.withdrawShow = true;
          break;
        case 2:
          this.withdrawRecordShow = true;
          break;
        case 3:
          this.shareProfitsShow = true;
          break;
      }
    },
    // 选择日期
    goSelectDate() {
      if (this.dateToDate) {
        this.tableParams.startTime = this.dateToDate[0];
        this.endTime.startTime = this.dateToDate[1];
      } else {
        this.tableParams.startTime = "";
        this.endTime.startTime = "";
      }
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
    },
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#merchantOrders {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
    .search-L {
      display: flex;
    }
  }
  .money {
    color: #000;
    font-size: 14px;
    margin-bottom: 0.15rem;
    display: flex;
    .item {
      margin-right: 20px;
    }
    .t1 {
      color: #999;
      font-size: 14px;
    }
    .t2 {
      font-size: 24px;
      color: #ef9807;
    }
    .t3 {
      font-size: 24px;
      color: #6dbe00;
    }
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
  .totalWithdrawmoney {
    color: #666;
    font-size: 14px;
    margin-bottom: 30px;
  }
}
</style>